@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:regular,bold,italic&subset=latin,latin-ext');

body {
  font-family: "Source Code Pro", cursive;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}